<template>
  <form style="max-width: 360px" @submit.prevent="onSave">
    <MultiSelect
      name="assign-group"
      v-model="selected"
      :options="groups"
      optionLabel="name"
      optionValue="_id"
    />
    <div class="form-input p-mt-2">
      <transition-group name="p-messages" tag="div">
        <Message
          v-for="msg of message"
          :severity="msg.severity"
          :key="msg.content"
        >
          {{ msg.content }}
        </Message>
      </transition-group>
    </div>
    <Button type="submit" class="font-semibold loading-btn p-mt-4">
      <spinner v-if="isLoading" color="white" />
      <span v-if="!isLoading">{{ i18n.$t("Save") }}</span>
    </Button>
  </form>
</template>

<script>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import Spinner from '../../components/UI/Spinner.vue';
import axios from '@/plugins/axios';
import { FETCH_GROUPS, GET_GROUPS, GET_USERDATA } from '../../store/types';
import useI18n from '@/plugins/useI18n';

export default {
  props: {
    preSelected: {
      type: Array,
      default: () => []
    }
  },
  components: {
    Spinner,
  },
  setup(props) {
    const { i18n } = useI18n();
    const store = useStore();
    const route = useRoute();
    const groups = computed(() => store.getters[GET_GROUPS]);
    const userData = computed(() => store.getters[GET_USERDATA]);

    const isLoading = ref(false);
    const message = ref([]);
    const selected = ref([]);
    const contactId = route.query.contact;
    store.dispatch(FETCH_GROUPS, userData.value.id);
    watch(
      () => [props.preSelected],
      () => {
        selected.value = props.preSelected;
      }
    );

    async function onSave() {
      isLoading.value = true;
      message.value = [];
      try {
        for (const group of selected.value) {
          const res = await axios.post(`group/invit/${group}`, [
            {
              _id: contactId,
              invit_status: 'Sent'
            }
          ]);
        }
        message.value = [
          { severity: 'success', content: 'Saved successfully' }
        ];
      } catch {
        message.value = [
          { severity: 'error', content: 'Oops, something went wrong!' }
        ];
      }
      isLoading.value = false;
    }

    return { i18n, groups, selected, onSave, isLoading, message };
  }
};
</script>

<style scoped>
.loading-btn {
  width: 100%;
  height: 40px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
